import React, { useEffect, Suspense, lazy} from 'react';
import { hydrate, render } from 'react-dom';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import './i18n';
import Loader from './components/Loader';

const App = lazy(() => import('./App'));
const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrate(
    <React.StrictMode>
      <Router>
        <ScrollToTop />
        <Suspense fallback={<Loader />}>
          <App />
        </Suspense>
      </Router>
    </React.StrictMode>,
  rootElement);
} else {
  render(
    <React.StrictMode>
      <Router>
        <ScrollToTop />
        <Suspense fallback={<Loader />}>
          <App />
        </Suspense>
      </Router>
    </React.StrictMode>,
    rootElement);
}

// render(
//   <React.StrictMode>
//     <Router>
//       <ScrollToTop />
//       <Suspense fallback={<Loader />}>
//         <App />
//       </Suspense>
//     </Router>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}