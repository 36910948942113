import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import localeNl from './locales/nl/strings.json';
import localeEn from './locales/en/strings.json';

const resources = {
  nl: { translation: localeNl },
  en: { translation: localeEn }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    supportedLngs: ['en', 'nl'],
    load: 'languageOnly',
    detection: {
      order: ['localStorage', 'navigator', 'htmlTag'],
      lookupFromPathIndex: 0,
      checkForSimilarInWhitelist: true,
    },
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
      format: function(value, formatting, lng) {
        let ret= '';
        let date = new Date(value);

        if (lng.split('-')[0] === 'nl') {
          let months = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'];
          ret = date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear();
        } else {
          let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
          let x = date.getDate() % 10;
          let suffix = (date.getDate() % 100) / 10 === 1 
                        ? 'th'
                        : x === 1
                        ? 'st'
                        : x === 2
                        ? 'nd'
                        : x === 3
                        ? 'rd'
                        : 'th';
                        
          ret = months[date.getMonth()]  + ' ' + date.getDate() + suffix + ' ' + date.getFullYear();
        }
        
        return ret;
      }
    }
  });

  export default i18n;